import { ADD_MENU, DELETE_MENU, UPDATE_ASIDE_SHOW } from './ActionType'

export const addMenu = (userLevelVersion, menuAccess, menuStructure) => ({
  type: ADD_MENU,
  userLevelVersion: userLevelVersion == null ? 0 : +userLevelVersion,
  menuAccess: menuAccess,
  menuStructure: menuStructure,
})

export const updateShowValue = (type, value) => ({
  type: type,
  payload: value,
})

export const deleteMenu = () => ({
  type: DELETE_MENU,
})

export const globalState = (type, value) => ({
  type: type,
  payload: value,
})
