import { combineReducers, createStore } from 'redux'
import MenuReducer from './reducer/MenuReducer'
import UserReducer from './reducer/UserReducer'

const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {}

const rootReducer = combineReducers({
  menu: MenuReducer,
  user: UserReducer,
})

const store = createStore(rootReducer, persistedState)

store.subscribe(() => {
  const state = store.getState()
  if (state.user.rememberMe) {
    localStorage.setItem('reduxState', JSON.stringify(state))
  } else {
    localStorage.setItem('reduxState', JSON.stringify({}))
    localStorage.setItem('activeUserState', JSON.stringify({}))
  }
})

export default store
