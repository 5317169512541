import { ADD_USER, DELETE_USER } from './ActionType'

export const addUser = (user) => ({
  type: ADD_USER,
  payload: user,
})

export const deleteUser = () => ({
  type: DELETE_USER,
})
