import axios from 'axios'
import Config from './Config'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { getMenuAccessByEmployeeIdAndUserRoleIdForRedux } from './MicroService'
import { addMenu, deleteMenu } from '../redux/actions/MenuAction'
import { deleteUser } from '../redux/actions/UserAction'
import { generateMenuStructure } from 'src/services/CommonUtil'

const instance = axios.create(Config)

export const setupAxiosInterceptors = (store, dispatch) => {
  instance.interceptors.request.use(
    (config) => {
      const { auth_str, userId, userTradeRole } = store.getState().user
      if (auth_str) {
        config.headers['Authorization'] = `Bearer ${auth_str}`
        config.headers['employeeId'] = `${userId}`
        config.headers['userLevelId'] = `${userTradeRole}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    (response) => {
      const newUserLevelVersion = response.headers[`user_level_role_access_version`]
      if (response.headers[`emp_role_access_version`] != null) reloadMenu(store, dispatch)
      else if (newUserLevelVersion != null) {
        const { userLevelVersion } = store.getState().menu
        if (userLevelVersion < +newUserLevelVersion) reloadMenu(store, dispatch)
      }
      return response
    },
    (error) => {
      if (error.response.data.code === 'UNAUTHORIZED') tokenExpired(dispatch)
      return Promise.reject(error)
    },
  )
}

const reloadMenu = (store, dispatch) => {
  const { userId, userTradeRole } = store.getState().user
  getMenuAccessByEmployeeIdAndUserRoleIdForRedux(userId, userTradeRole).then((response) => {
    if (response.status === 200) {
      var menuStructure = generateMenuStructure(response.data)
      dispatch(
        addMenu(response.headers[`user_level_role_access_version`], response.data, menuStructure),
      )
    }
  })
}

const tokenExpired = (dispatch) => {
  dispatch(deleteUser())
  dispatch(deleteMenu())
  window.location.hash = '/'
  window.location.reload()
}

export default instance
