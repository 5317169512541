export const getBaseUrl = () => {
  const defaultUrl = 'http://localhost:9090/rest'
  let url = process.env.REACT_APP_API_URL || defaultUrl
  return url
}

export const config = {
  baseURL: getBaseUrl(),
  version: '1.0.0',
}

export default config
