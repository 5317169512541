import {
  ADD_MENU,
  DELETE_MENU,
  UPDATE_ASIDE_SHOW,
  UPDATE_SIDEBAR_SHOW,
  UPDATE_SIDEBAR_UNFOLDABLE,
  UPDATE_ACTIVE_MODULE,
} from '../actions/ActionType'

const initialData = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  userLevelVersion: null,
  menuAccess: [],
  menuStructure: [],
}

export default (state = initialData, action) => {
  switch (action.type) {
    case ADD_MENU:
      return {
        ...state,
        userLevelVersion: action.userLevelVersion,
        menuAccess: action.menuAccess,
        menuStructure: action.menuStructure,
      }

    case UPDATE_ASIDE_SHOW:
      return {
        ...state,
        asideShow: action.payload,
      }

    case UPDATE_SIDEBAR_SHOW:
      return {
        ...state,
        sidebarShow: action.payload,
      }

    case UPDATE_SIDEBAR_UNFOLDABLE:
      return {
        ...state,
        sidebarUnfoldable: action.payload,
      }

    case UPDATE_ACTIVE_MODULE:
      return {
        ...state,
        activeModule: action.payload,
      }

    case DELETE_MENU:
      return initialData

    default:
      return state
  }
}
