import { ADD_USER, DELETE_USER } from '../actions/ActionType'

const initialData = {
  auth_str: null,
  companyId: null,
  userId: null,
  userCode: null,
  loginId: null,
  userTradeRole: null,
  userTradeRoleName: null,
  unautherror: null,
  userDesignationId: null,
  userDesignationName: null,
  userDepartmentId: null,
  userDepartmentName: null,
  tokenExpiredTime: null,
  rememberMe: false,
}

const userReducer = (state = initialData, action) => {
  switch (action.type) {
    case ADD_USER:
      return action.payload

    case DELETE_USER:
      return initialData

    default:
      return state
  }
}

export default userReducer
