export const ADD_MENU = 'ADD_MENU'
export const DELETE_MENU = 'DELETE_MENU'

export const UPDATE_ASIDE_SHOW = 'UPDATE_ASIDE_SHOW'
export const UPDATE_SIDEBAR_SHOW = 'UPDATE_SIDEBAR_SHOW'
export const UPDATE_SIDEBAR_UNFOLDABLE = 'UPDATE_SIDEBAR_UNFOLDABLE'

export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'

export const UPDATE_ACTIVE_MODULE = 'UPDATE_ACTIVE_MODULE'
