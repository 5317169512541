import { format } from 'date-fns'
import * as Icons from '@coreui/icons-pro'
import { CNavGroup, CNavItem } from '@coreui/react-pro'
import React, { useEffect, useRef } from 'react'
import CIcon from '@coreui/icons-react'
import moment from 'moment-timezone'

export const customGlobal = {
  TIMESHEET_MODULE: 'TIMESHEET_MODULE',
  PROJECT_MANAGEMENT_MODULE: 'PROJECT_MANAGEMENT_MODULE',
  QUALITY_MANAGEMENT_MODULE: 'QUALITY_MANAGEMENT_MODULE',
  DASHBOARD_ANALYTICS_MODULE: 'DASHBOARD_ANALYTICS_MODULE',
  MASTER_CONFIGURATION_MODULE: 'MASTER_CONFIGURATION_MODULE',
  PAGE_UNAUTHORIZED_ACCESS: '/401',
  PAGE_NOT_FOUND: '/404',
}

export const defaultPageRights = {
  isCreate: false,
  isDelete: false,
  isEdit: false,
  isView: false,
}

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '3px 8px',
  }),
  menu: (provided, state) => ({
    ...provided,
    overflow: 'hidden', // Hide overflow content
    maxHeight: '100px',
    border: `1px dotted black`,
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '100px', // Customize the max height
    overflowY: 'auto', // Enable vertical scrolling
  }),
}

export const customStylesMin = {
  option: (provided, state, isDisabled, isSelected, isFocused) => ({
    ...provided,
    // backgroundColor: state.isSelected && 'red',
    // color: state.isSelected ? 'white' : 'black',
    fontSize: '14px',
    padding: '3px 8px',
  }),
  control: (provided) => ({
    ...provided,
    // border: '2px solid #333',
  }),
  menu: (provided, state) => ({
    ...provided,
    overflow: 'hidden', // Hide overflow content
    border: `1px dotted black`,
  }),
  menuList: (provided, state) => ({
    ...provided,
    overflowY: 'auto', // Enable vertical scrolling
  }),
}

export const configRole = {
  //User Level
  ADMIN: 1,
  HR: 9,
  PM: 4,
  QAM: 6,
  BM: 2,
  CHECKER: 10,
  MODELLER: 11,
  DETAILER: 12,
}

export const configDesignation = {
  QA_MANAGER: 40,
  QA_CHECKER: 51,
  QA_JUNIOR_CHECKER: 46,
  QA_SENIOR_CHECKER: 25,
}

export const configTradeRole = {
  PROJECTMANAGER: 1,
  TEAMLEAD: 2,
  CHECKER: 3,
  MODELLER: 4,
  DETAILER: 5,
  ADMIN: 18,
  QUALITY_ASSURANCE: 9,
}

export const configDepartment = {
  PRODUCTION: 12,
}

export const projectStatus = {
  HOLD: 6,
  CANCELLED: 7,
  COMPLETED: 5,
}

export const configMileStone = {
  SubmissionofABOM: 1,
  SubmissionofAnchorBolts: 2,
  SubmissionofIFA: 3,
  IFACommentsreceivedfromClientBFA: 4,
  ResubmissionofIFA: 5,
  SubmissionofIFF: 6,
  SubmissionofBoltReportsBuyingList: 7,
  SubmissionofEsheetstoField: 8,
  RFACommentsfromClient: 9,
  QAReview: 10,
}

export const configMileStoneShortOrder = {
  SubmissionofABOM: 1,
  SubmissionofAnchorBolts: 2,
  SubmissionofIFA: 3,
  IFACommentsreceivedfromClientBFA: 4,
  ResubmissionofIFA: 5,
  SubmissionofIFF: 7,
  SubmissionofBoltReportsBuyingList: 9,
  SubmissionofEsheetstoField: 8,
  RFACommentsfromClient: 6,
  QAReview: 10,
}

export const configCustomNCRType = {
  MAJOR: true,
  MINOR: false,
}

export const configNCRTypeOrigin = {
  QA: 'QA',
  QC: 'QC',
}

export const configScreenNCRType = {
  INTERNAL: 1,
  EXTERNAL: 2,
  QMS: 3,
}

export const configNCRStage = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  '4A': '4A',
  '4B': '4B',
  5: '5',
  '5A': '5A',
  '5B': '5B',
  '5C': '5C',
  6: '6',
  '6A': '6A',
  '6B': '6B',
}

export const configNCRStageByConfigRole = {
  [configRole.QAM]: [
    configNCRStage['1'],
    configNCRStage['4B'],
    configNCRStage['5B'],
    configNCRStage['6A'],
    configNCRStage['6B'],
  ],
  [configRole.PM]: [
    configNCRStage['2'],
    configNCRStage['3'],
    configNCRStage['4A'],
    configNCRStage['5A'],
    configNCRStage['5C'],
  ],
}

export const greetingsMessage = () => {
  const currentTime = new Date()
  const currentHour = currentTime.getHours()

  if (currentHour >= 0 && currentHour < 12) {
    return 'Good Morning'
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Good Afternoon'
  } else {
    return 'Good Evening'
  }
}

export const toCamelCase = (value) => {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const formatDate = (value) => {
  const formattedDate = format(value, 'dd-MMM-yyyy')
  return formattedDate
}

export const toSnakeCase = (value) => {
  const sentenceCaseText = value
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

  return sentenceCaseText
}

export const updateMasterConfigIsActive = (values) =>
  values.map((element) => {
    if ('is_active' || 'isActive' in element) {
      const formattedDate = element.date ? moment(element.date).format('DD-MMM-yyyy') : '-'
      const isActive = element.is_active || element.isActive ? 'Active' : 'Inactive'
      const milestoneTimesheetFlag = element.milestone_timesheet_flag ? 'True' : 'False'
      const tsActivityBudgetAvailable = element.ts_activity_budget_available ? 'True' : 'False'
      const resourceAllocationAllowed = element.resource_allocation_allowed ? 'True' : 'False'

      return {
        ...element,
        date: formattedDate,
        active: isActive,
        milestone_timesheet_flag: milestoneTimesheetFlag,
        ts_activity_budget_available: tsActivityBudgetAvailable,
        resource_allocation_allowed: resourceAllocationAllowed,
      }
    }

    return element
  })

export const drawingCheckTableConfig = (values, categoryDropdown, severityDropdown) =>
  values.map((element) => {
    if ('stage' in element) {
      const agreedNotAgreed =
        element.agreedOrDisagree === true
          ? 'Yes'
          : element.agreedOrDisagree === false
          ? 'No'
          : '_blank'

      const drawingCategoryLabel =
        element.categoryId !== null &&
        categoryDropdown?.find((option) => option.value === element.categoryId)

      const drawingSeverityLabel =
        element.categoryId !== null &&
        severityDropdown?.find((option) => option.value === element.severityId)

      return {
        ...element,
        field_agree_notagree: agreedNotAgreed,
        field_category: drawingCategoryLabel?.label,
        field_severity: drawingSeverityLabel?.label,
      }
    }

    return element
  })

export const stagingTableConfig = (values) =>
  values.map((element) => {
    if ('stage' in element) {
      const stageNumber = element.stage !== null ? element.stage : '-'

      return {
        ...element,
        current_stage: stageNumber,
      }
    }

    return element
  })

export const generateNav = (menuStructure, menuEnumScreen) => {
  const _nav = []
  const defaultIcon = 'cisMediaRecord'
  const menuslen = menuStructure?.length

  for (let i = 0; i < menuslen; i++) {
    const menusObj = menuStructure[i]
    const menuEnum = menusObj.menuEnum
    const lowerMenuEnum = menuEnum.toLowerCase()

    if (menuEnum === menuEnumScreen) {
      const childL1Obj = menusObj.child || []
      const childL1ObjLen = childL1Obj.length
      for (let j = 0; j < childL1ObjLen; j++) {
        const subMenuL1Obj = childL1Obj[j]
        const subMenuL1Name = subMenuL1Obj.menuName
        const subMenuL1Enum = subMenuL1Obj.menuEnum
        const subMenuL1isView = subMenuL1Obj.isView
        const subMenuL1Icon =
          subMenuL1Obj.menuIcon !== undefined ? subMenuL1Obj.menuIcon : defaultIcon
        const lowerSubMenuL1Enum = subMenuL1Enum.toLowerCase()
        const childL2Obj = subMenuL1Obj.child || []
        const childL2ObjLen = childL2Obj.length

        if (subMenuL1isView || childL2ObjLen > 0) {
          const itemsL1 = []

          for (let k = 0; k < childL2ObjLen; k++) {
            const subMenuL2Obj = childL2Obj[k]
            const subMenuL2Name = subMenuL2Obj.menuName
            const subMenuL2Enum = subMenuL2Obj.menuEnum
            const subMenuL2isView = subMenuL2Obj.isView
            const subMenuL2Icon =
              subMenuL2Obj.menuIcon !== undefined ? subMenuL2Obj.menuIcon : defaultIcon
            const lowerSubMenuL2Enum = subMenuL2Enum.toLowerCase()
            const childL3Obj = subMenuL2Obj.child || []
            const childL3ObjLen = childL3Obj.length

            if (subMenuL2isView || childL3ObjLen > 0) {
              const itemsL2 = []

              for (let l = 0; l < childL3ObjLen; l++) {
                const subMenuL3Obj = childL3Obj[l]
                const subMenuL3Name = subMenuL3Obj.menuName
                const subMenuL3Enum = subMenuL3Obj.menuEnum
                const subMenuL3isView = subMenuL3Obj.isView
                const subMenuL3Icon =
                  subMenuL3Obj.menuIcon !== undefined ? subMenuL3Obj.menuIcon : defaultIcon
                const lowerSubMenuL3Enum = subMenuL3Enum.toLowerCase()
                const childL4Obj = subMenuL3Obj.child || []
                const childL4ObjLen = childL4Obj.length

                if (subMenuL3isView || childL4ObjLen > 0) {
                  const itemsL3 = []

                  for (let m = 0; m < childL4ObjLen; m++) {
                    const subMenuL4Obj = childL4Obj[m]
                    const subMenuL4Name = subMenuL4Obj.menuName
                    const subMenuL4Enum = subMenuL4Obj.menuEnum
                    const subMenuL4isView = subMenuL4Obj.isView
                    const subMenuL4Icon =
                      subMenuL4Obj.menuIcon !== undefined ? subMenuL4Obj.menuIcon : defaultIcon
                    const lowerSubMenuL4Enum = subMenuL4Enum.toLowerCase()

                    if (subMenuL4isView) {
                      itemsL3.push({
                        component: CNavItem,
                        name: subMenuL4Name,
                        icon: <CIcon icon={Icons[subMenuL4Icon]} customClassName="nav-icon" />,
                        to:
                          '/' +
                          lowerMenuEnum +
                          '/' +
                          lowerSubMenuL1Enum +
                          '/' +
                          lowerSubMenuL2Enum +
                          '/' +
                          lowerSubMenuL3Enum +
                          '/' +
                          lowerSubMenuL4Enum,
                      })
                    }
                  }

                  if (
                    itemsL3.length > 0 &&
                    menuEnumScreen !== customGlobal.MASTER_CONFIGURATION_MODULE
                  ) {
                    /* NEED_TO_FIX */
                    itemsL2.push({
                      component: CNavGroup,
                      name: subMenuL3Name,
                      icon: <CIcon icon={Icons[subMenuL3Icon]} customClassName="nav-icon" />,
                      to:
                        '/' +
                        lowerMenuEnum +
                        '/' +
                        lowerSubMenuL1Enum +
                        '/' +
                        lowerSubMenuL2Enum +
                        '/' +
                        lowerSubMenuL3Enum,
                      items: itemsL3,
                    })
                  } else {
                    if (childL4ObjLen <= 0) {
                      itemsL2.push({
                        component: CNavItem,
                        name: subMenuL3Name,
                        to:
                          '/' +
                          lowerMenuEnum +
                          '/' +
                          lowerSubMenuL1Enum +
                          '/' +
                          lowerSubMenuL2Enum +
                          '/' +
                          lowerSubMenuL3Enum,
                      })
                    }
                  }
                }
              }

              if (
                itemsL2.length > 0 &&
                menuEnumScreen !== customGlobal.MASTER_CONFIGURATION_MODULE
              ) {
                /* NEED_TO_FIX */
                itemsL1.push({
                  component: CNavGroup,
                  name: subMenuL2Name,
                  icon: <CIcon icon={Icons[subMenuL2Icon]} customClassName="nav-icon" />,
                  to: '/' + lowerMenuEnum + '/' + lowerSubMenuL1Enum + '/' + lowerSubMenuL2Enum,
                  items: itemsL2,
                })
              } else {
                if (childL3ObjLen <= 0) {
                  itemsL1.push({
                    component: CNavItem,
                    name: subMenuL2Name,
                    to: '/' + lowerMenuEnum + '/' + lowerSubMenuL1Enum + '/' + lowerSubMenuL2Enum,
                  })
                }
              }
            }
          }

          if (itemsL1.length > 0 && menuEnumScreen !== customGlobal.MASTER_CONFIGURATION_MODULE) {
            /* NEED_TO_FIX */
            _nav.push({
              component: CNavGroup,
              name: subMenuL1Name,
              to: '/' + lowerMenuEnum + '/' + lowerSubMenuL1Enum,
              icon: <CIcon icon={Icons[subMenuL1Icon]} customClassName="nav-icon" />,
              items: itemsL1,
            })
          } else {
            if (childL2ObjLen <= 0 || menuEnumScreen === customGlobal.MASTER_CONFIGURATION_MODULE) {
              _nav.push({
                component: CNavItem,
                name: subMenuL1Name,
                to: '/' + lowerMenuEnum + '/' + lowerSubMenuL1Enum,
                icon: <CIcon icon={Icons[subMenuL1Icon]} customClassName="nav-icon" />,
              })
            }
          }
        }
      }
    }
  }
  return _nav
}

export const generateMenuStructure = (responseData) => {
  if (!Array.isArray(responseData)) {
    return []
  }

  const menuMap = new Map()

  // Initialize the map with menu items
  responseData.forEach((item) => {
    item.child = []
    menuMap.set(item.menuId, item)
  })

  // Build the hierarchy
  responseData.forEach((item) => {
    if (item.parentMenuId !== null) {
      const parent = menuMap.get(item.parentMenuId)
      if (parent) {
        parent.child.push(item)
      }
    }
  })

  // Collect top-level menus
  const menuStructure = responseData.filter((item) => item.parentMenuId === null)
  return menuStructure
}

const hasVisibleChild = (menu) => {
  if (!menu || !Array.isArray(menu.child)) return false
  if (menu.isView) return true
  return menu.child.some(hasVisibleChild)
}

export const validateModuleView = (menuStructure, menuEnumScreen) => {
  if (!Array.isArray(menuStructure)) return false

  const menu = menuStructure.find((menu) => menu.menuEnum === menuEnumScreen)
  return menu ? hasVisibleChild(menu) : false
}

const findChildren = (menu, menuIdScreen, resultChilds, isCollapse) => {
  if (!menu || !Array.isArray(menu.child)) return

  // If the current menu matches the screen ID
  if (menu.menuId === menuIdScreen) {
    menu.child.forEach((child) => {
      resultChilds.push(child.menuId)
      // If collapsing is enabled, find all children recursively
      if (isCollapse && child.isCollapse) {
        findChildren(child, child.menuId, resultChilds, isCollapse)
      }
    })
  } else {
    // Recursively search in children
    menu.child.forEach((child) => findChildren(child, menuIdScreen, resultChilds, isCollapse))
  }
}

export const getAllMenusChildsByMenuId = (getMenuList, menuStructure, menuIdScreen, isCollapse) => {
  if (!Array.isArray(menuStructure)) return []

  const resultChilds = []
  menuStructure.forEach((menu) => findChildren(menu, menuIdScreen, resultChilds, isCollapse))
  return resultChilds
}

export const getParentMenuIDByMenuID = (menus, menuIdScreen) => {
  var parentMenuId = null
  var menuslen = menus?.length
  for (var i = 0; i < menuslen; i++) {
    var menusObj = menus[i]
    if (menusObj.menuId === menuIdScreen) {
      parentMenuId = menusObj.parentMenuId
      break
    }
  }
  return parentMenuId
}

export const getMenuLevel = (menuStructure, menuIdScreen, isHeaderLevel) => {
  var MenuLevel = [null, 0]
  var menuslen = menuStructure?.length
  for (var i = 0; i < menuslen; i++) {
    var menusObj = menuStructure[i]
    var menuId = menusObj.menuId

    var childL1Obj = menusObj.child
    var childL1ObjLen = childL1Obj.length

    for (var j = 0; j < childL1ObjLen; j++) {
      var subMenuL1Obj = childL1Obj[j]
      var subMenuL1menuId = subMenuL1Obj.menuId
      var childL2Obj = subMenuL1Obj.child
      var childL2ObjLen = childL2Obj.length

      for (var k = 0; k < childL2ObjLen; k++) {
        var subMenuL2Obj = childL2Obj[k]
        var subMenuL2menuId = subMenuL2Obj.menuId

        var childL3Obj = subMenuL2Obj.child
        var childL3ObjLen = childL3Obj.length

        for (var l = 0; l < childL3ObjLen; l++) {
          var subMenuL3Obj = childL3Obj[l]
          var subMenuL3menuId = subMenuL3Obj.menuId

          var childL4Obj = subMenuL3Obj.child
          var childL4ObjLen = childL4Obj.length

          for (var s = 0; s < childL4ObjLen; s++) {
            var subMenuL4Obj = childL4Obj[s]
            var subMenuL4menuId = subMenuL4Obj.menuId

            if (subMenuL4menuId === menuIdScreen) {
              MenuLevel = ['ML4', 60]
              return MenuLevel
            }
          }

          if (subMenuL3menuId === menuIdScreen) {
            if (childL4ObjLen > 0 && isHeaderLevel) {
              console.log('MH4')
              MenuLevel = ['MH4', 0]
              return MenuLevel
            }
            MenuLevel = ['ML3', 45]
            return MenuLevel
          }
        }
        if (subMenuL2menuId === menuIdScreen) {
          if (childL3ObjLen > 0 && isHeaderLevel) {
            MenuLevel = ['MH3', 0]
            return MenuLevel
          }
          MenuLevel = ['ML2', 30]
          return MenuLevel
        }
      }
      if (subMenuL1menuId === menuIdScreen) {
        if (childL2ObjLen > 0 && isHeaderLevel) {
          MenuLevel = ['MH2', 0]
          return MenuLevel
        }
        MenuLevel = ['ML1', 15]
        return MenuLevel
      }
    }
    if (menuId === menuIdScreen) {
      if (childL1ObjLen > 0 && isHeaderLevel) {
        MenuLevel = ['MH1', 0]
        return MenuLevel
      }
      MenuLevel = ['M', 0]
      return MenuLevel
    }
  }

  return MenuLevel
}

export const getPageRights = (menus, menuEnumScreen) => {
  var resultObj = {
    isCreate: false,
    isDelete: false,
    isEdit: false,
    isView: false,
  }

  var menusLen = menus.length
  for (var i = 0; i < menusLen; i++) {
    var menusObj = menus[i]
    var menusEnum = menusObj.menuEnum
    var menuId = menusObj.menuId
    if (menusEnum === menuEnumScreen && menuEnumScreen !== 'MASTER_CONFIGURATION') {
      /* NEED_TO_FIX */
      resultObj.isCreate = menusObj.isCreate
      resultObj.isDelete = menusObj.isDelete
      resultObj.isEdit = menusObj.isEdit
      resultObj.isView = menusObj.isView
      break
    } else if (menusEnum === menuEnumScreen && menuEnumScreen === 'MASTER_CONFIGURATION') {
      /* NEED_TO_FIX */
      for (var j = 0; j < menusLen; j++) {
        var menus1Obj = menus[j]
        var menus1ObjisView = menus1Obj.isView

        if (menus1ObjisView && menus1Obj.parentMenuId === menuId) {
          resultObj.isCreate = false
          resultObj.isDelete = false
          resultObj.isEdit = false
          resultObj.isView = menus1ObjisView
          break
        }
      }
    }
  }
  return resultObj
}

export const generateLandingPage = (menuStructure, menuEnumScreen) => {
  let landingPage = ''
  const landingPagePrefix = '#/'

  // Helper function to build the URL path from the menu items
  const buildPath = (menu, pathSegments, level = 1) => {
    const currentPath = `${landingPagePrefix}${pathSegments.join('/')}`

    if (menu.isView || menu.child.length > 0) {
      landingPage = currentPath

      // Process child menus
      for (const child of menu.child) {
        // Check if the child has view access or has accessible children
        if (child.isView || child.child.some((c) => c.isView || c.child.length > 0)) {
          // Avoid processing second-level children if menuEnumScreen is "MASTER_CONFIGURATION_MODULE"
          if (menuEnumScreen === 'MASTER_CONFIGURATION_MODULE' && level === 2) {
            break
          }
          buildPath(child, [...pathSegments, child.menuEnum.toLowerCase()], level + 1)
          // Only process one level of child menus to avoid excessive nesting
          break
        }
      }
    }
  }

  // Find the menu with the specified enum and start building the path
  for (const menu of menuStructure) {
    if (menu.menuEnum === menuEnumScreen) {
      buildPath(menu, [menu.menuEnum.toLowerCase()])
      break
    }
  }

  return landingPage
}

export function useInterval(callback, delay) {
  // Creating a ref
  const savedCallback = useRef()

  // To remember the latest callback .
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // combining the setInterval and
  //clearInterval methods based on delay.
  useEffect(() => {
    function func() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(func, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const reportFilterDropdown = [
  {
    value: 'COMPLETED',
    label: 'Completed Project',
  },
  {
    value: 'CURRENT',
    label: 'Current Project',
  },
  {
    value: 'ALL',
    label: 'All Projects',
  },
]
